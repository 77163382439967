<template>
  <div>
    <el-card>
      <div class=  "flexRow">
        <!-- <div class = "flexRow">
          <div class = "keyfont">创建来源：</div>
          <el-select v-model="source" style="width:180px">
            <el-option v-for="v in sourcelist" :key="v.value" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div> -->
        <!-- <el-button type="primary" style="margin-left:10px" @click="query">查询</el-button> -->
        <!-- <el-button type="primary">添加客户群发</el-button> -->
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="" label="群发内容" width="400px">
            <template slot-scope="scope">
              <div class = "flexRow">
                <div class = "fonthidden" style="max-width:400px">
                  {{scope.row.Content}}
                </div>
                <div style="width:200px;white-space:nowrap">
                  <span v-if="scope.row.QyWeixinAttachmentInfoList.some((v)=>{return v.Type ==1})">【图片】</span>
                  <span v-if="scope.row.QyWeixinAttachmentInfoList.some((v)=>{return v.Type ==2})">【链接】</span>
                  <span v-if="scope.row.QyWeixinAttachmentInfoList.some((v)=>{return v.Type ==3})">【小程序】</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="QyWeixinGroupMessageFromTypeValue" label="创建来源" width="300px">
            <template slot-scope="scope">
              <div class = "fonthidden">
               {{scope.row.QyWeixinGroupMessageFromTypeValue}}-{{scope.row.Name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="创建时间" width="160px"></el-table-column>
          <el-table-column prop="EmployeeHaveSendCount" label="已发送成员"></el-table-column>
          <el-table-column prop="SendMemberSuccessCount" label="已送达客户"></el-table-column>
          <el-table-column prop="EmployeeNotSendCount" label="未发送成员"></el-table-column>
          <el-table-column prop="NotSendMemberCount" label="未送达客户"></el-table-column>
          <el-table-column prop="SendMemberFailCount" label="发送失败客户"></el-table-column>
          <el-table-column prop="" label="操作" width="150px">
            <template slot-scope="scope">
              <buttonPermissions v-if="scope.row.EmployeeNotSendCount!=0" :datas="'remindstaff'" style="margin-right:5px">
                <el-button type="text" @click="remindstaff(scope.row)">提醒员工</el-button>
              </buttonPermissions>
              <el-button type="text" @click="todetails(scope.row)">群发详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align:right;margin-top:10px" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import{
  qyweixingroupmessageqyweixingroupmessagerecordlist,
  qyweixingroupmessageqyweixingroupmessageremindemployee
}from "@/api/sv3.0.0"
import buttonPermissions from '@/components/buttonPermissions';
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
      source:null,
      sourcelist:[
        {value:null,label:'全部'},
        {value:1,label:'自动营销任务'},
        {value:2,label:'企微客户裂变'},
        {value:3,label:'企业微信后台创建'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      sizepage:20,
      total:null,
    }
  },
  created () {
    this.gettablelist()
  },
  methods: {
    remindstaff(e){
      this.$confirm('是否确认给所有未发送成员推送提醒？系统将通过【企店助手】提醒员工，员工需要进入企业微信点击发送', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.toremindstaff(e)
      }).catch(() => {});
    },
    async toremindstaff(e){
      this.tableloading = true
      try{
        let msg = await e
        let data = {
          Id:msg.Id,
          UserId:'',
        }
        let result = await qyweixingroupmessageqyweixingroupmessageremindemployee(data)
        if(result.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    todetails(e){
      this.$router.push({
        path:'/qyCustomer/Massrecord/MassrecordDetails',
        query:{
          Id:e.Id
        }
      })
    },
    query(){
      this.currentPage=1
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage=1
      this.sizepage=e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage=e
      this.gettablelist()
    },
    async gettablelist(){
      this.tableloading=true
      try{
        let data = {
          AutoMarketingId:this.$route.params.Id?this.$route.params.Id:'',
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage,
        }
        // console.log(data)
        let result = await qyweixingroupmessageqyweixingroupmessagerecordlist(data)
        if(result.IsSuccess){
          this.tablelist = result.Result.Results
          this.total = result.Result.Total
        }
      }finally{
        this.tableloading=false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .keyfont{
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    white-space: nowrap;
  }
  .fonthidden{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
</style>